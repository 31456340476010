<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="pa-4 text-center">

    <v-dialog v-model="propsClienteProducto.dialog" persistent>
      <v-card>
        <v-toolbar class="text-h6 ps-4" color="primary" dark> Productos </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headers" 
            :items="items.content"
            :server-items-length="items.totalElements"
            items-per-page-text="Items por página"
            :items-per-page-options="itemsPerPageOptions" 
            @update:items-per-page="(i) => cambiarItemsPagina(i)"
            @update:page="(p) => cambiarPagina(p)"
            class="tableResults ma-3" >
            <template v-slot:header.config>
                <v-btn 
                  small
                  class="ms-2" 
                  color="success" 
                  @click="cargarProductos()"
                >
                  <v-icon dark> cloud_upload </v-icon>
                </v-btn>
            </template>
            <template v-slot:item.config="{ item }">
                <v-btn 
                  small
                  variant="text" 
                  class="ms-2" 
                  color="warning"
                  @click="dialogConsolidado(item)"
                >
                <v-icon dark> filter_list </v-icon>
                </v-btn>
            </template>
          </v-data-table>


        <div class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn size="small" color="error" variant="plain" @click="cerrarDialog()">
            Cerrar
          </v-btn>
        </div>
        </v-card-text>

        <v-divider></v-divider>

      </v-card>

      <HomologadorConsolidado :propsConsolidado="propsConsolidado"></HomologadorConsolidado>
    </v-dialog>

    <v-overlay :opacity="0.5" v-model="estaCargtando" class="d-flex justify-center h-100 align-center">
      <v-progress-circular indeterminate :size="120" color="white">
        Cargando...
      </v-progress-circular>
    </v-overlay>

  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations } from "vuex";
import HomologadorConsolidado from "@/components/homologador/HomologadorConsolidado.vue";

export default {
  components: {
    HomologadorConsolidado
  },
  created() { },
  data() {
    return {
      estaCargtando: false,
      timer: null,
      headers: [
        { title: "CODIGO PRODUCTO", value: "codigoCliente", sortable: false },
        { title: "CUMS", value: "cums", sortable: false },
        { title: "N. COMERCIAL", value: "nombreComercial", sortable: false },
        { title: "N. GENERICO", value: "nombreGenerico", sortable: false },
        { title: "LABORATORIO", value: "laboratorio", sortable: false },
        { title: "", value: "config", sortable: false },
      ],
      itemsPerPageOptions: [10, 20, 30, 40],
      items: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        numberOfElements: 0,
        number: 0,
        size: 10
      },
      propsConsolidado: {
        dialog: false,
        codigoStone: null,
        idClienteProducto: null
      }
    }
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    inputHandler(func) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if(typeof func == 'function'){
          func();
        }
      }, 500);
    },
    cambiarItemsPagina(size) { this.items.size = size; this.getProductos(); },
    cambiarPagina(page) { this.items.number = page - 1; this.getProductos(); },
    cerrarDialog(){
      this.propsClienteProducto.dialog = false;
      this.items = {
        content: [],
        totalElements: 0,
        totalPages: 0,
        numberOfElements: 0,
        number: 0,
        size: 10
      }
    },
    getProductos() {
      this.items.content = [];
      this.$http.get('msa-tools/api/v1/homologador-cliente-producto', {
        params: {
          size: this.items.size,
          page: this.items.number,
          idCliente: this.propsClienteProducto.idCliente,
          sort: "idClienteProducto,desc"
        }
      }
      ).then(({ data }) => {
        this.items = data;
      })
      .catch(error => {
        console.error(error);
        this.showError(error.response.data.mensaje.join(','))
      })
    },
    cargarProductos(){
      const idCliente = this.propsClienteProducto.idCliente;
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.id = 'productosUpload';
      fileInput.click();
      fileInput.addEventListener('change', async (event) => {
          this.estaCargtando = true;
          const file = event.target.files[0];
          if (file) {
            const formData = new FormData();
            formData.append('file', file);
            this.$http.post(`msa-tools/api/v1/homologador-cliente-producto/file/${idCliente}`,formData, { headers: { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data'  } })
            .then(()=>{
              this.showSuccess('Productos adicionados con exito.');
              this.getProductos();
              fileInput.remove();
              this.estaCargtando = false;
            })
            .catch(error => {
              console.error('salio mal: ', error);
              this.showError(error.response.data.mensaje.join(','))
              fileInput.remove();
              this.estaCargtando = false;
            })
          }
        });
    },
    dialogConsolidado(item){
      this.propsConsolidado = {
        idClienteProducto: item.idClienteProducto,
        codigoStone: item.codigoStone,
        dialog: true,
      }
    }
  },
  watch: {
    'propsClienteProducto.dialog': function(newValue) {
      if(newValue){
        this.getProductos();
      }
    },
  },
  computed: {},
  mounted() {
    
  },
  props: {
    propsClienteProducto: Object,
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .tableResults div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tableResults div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tableResults div {
  --sb-track-color: #d1cfcf;
  --sb-thumb-color: #8b8585;
  --sb-size: 8px;
}

::v-deep .tableResults div::-webkit-scrollbar {
  width: var(--sb-size)
}

::v-deep .tableResults div::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

::v-deep .tableResults div::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;
}
</style>