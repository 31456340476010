<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="pa-4 text-center">
    <v-dialog v-model="propsConsolidado.dialog" persistent>
      <v-card>
        <v-toolbar class="text-h6 ps-4" color="primary" dark> Consolidado </v-toolbar>
        <v-card-text>
          
          <v-data-table 
            :headers="headers" 
            :items="items.content"
            :server-items-length="items.totalElements"
            items-per-page-text="Items por página"
            :items-per-page-options="itemsPerPageOptions" 
            @update:items-per-page="(i) => cambiarItemsPagina(i)"
            @update:page="(p) => cambiarPagina(p)"
            class="tableResults ma-3" >

            <template v-slot:item.prioridad="{item}">
              <input type="checkbox"  v-model="item.prioridad" @click="cambiarPrioridad(item)">
            </template>

          </v-data-table>

        <div class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn size="small" color="error" variant="plain" @click="cerrarDialog()">
            Cerrar
          </v-btn>
        </div>
        </v-card-text>

        <v-divider></v-divider>

      </v-card>
    </v-dialog>
    
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations } from "vuex";


export default {
  components: {},
  created() { },
  data() {
    return {
      timer: null,
      headers: [
        { title: "CODIGO STONE", value: "codigoStone", sortable: false },
        { title: "NOMBRE STONE", value: "descripcionStone", sortable: false },
        { title: "CANTIDAD", value: "cantidad", sortable: false },
        { title: "PRIORIDAD", value: "prioridad", sortable: false },
      ],
      itemsPerPageOptions: [10, 20, 30, 40],
      items: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        numberOfElements: 0,
        number: 0,
        size: 10
      },
    }
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    inputHandler(func) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if(typeof func == 'function'){
          func();
        }
      }, 500);
    },
    boolToString(bool){
      return bool ? 'Si' : 'No';
    },
    colorSwitch(prioridad) {
      return prioridad ? 'error' : 'success';
    },
    cambiarItemsPagina(size) { this.items.size = size; this.getConsolidado(); },
    cambiarPagina(page) { this.items.number = page - 1; this.getConsolidado(); },
    cerrarDialog(){
      this.propsConsolidado.dialog = false;
      this.items = {
        content: [],
        totalElements: 0,
        totalPages: 0,
        numberOfElements: 0,
        number: 0,
        size: 10
      }
    },
    getConsolidado() {
      this.items.content = [];
      this.$http.get('msa-tools/api/v1/homologador-consolidado', {
        params: {
          size: this.items.size,
          page: this.items.number,
          idClienteProducto: this.propsConsolidado.idClienteProducto,
          sort: "idConsolidado.idClienteProducto,desc"
        }
      }
      ).then(({ data }) => {
        this.items = data;
      })
      .catch(error => {
        console.error(error);
        this.showError(error.response.data.mensaje.join(','))
      })
    },
    cambiarPrioridad(item){
      this.$http.put(`msa-tools/api/v1/homologador-consolidado/${item.idClienteProducto}/${item.codigoStone}`)
      .then(() => {
        this.getConsolidado();
        this.showSuccess("Prioridad actualizada.")
      })
      .catch(error => {
        console.error(error);
        this.showError(error.response.data.mensaje.join(','))
      })
    }
  },
  watch: {
    'propsConsolidado.dialog': function(newValue) {
      if(newValue){
        this.getConsolidado();
      }
    },
  },
  computed: { },
  mounted() {
    
  },
  props: {
    propsConsolidado: Object,
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .tableResults div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tableResults div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tableResults div {
  --sb-track-color: #d1cfcf;
  --sb-thumb-color: #8b8585;
  --sb-size: 8px;
}

::v-deep .tableResults div::-webkit-scrollbar {
  width: var(--sb-size)
}

::v-deep .tableResults div::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

::v-deep .tableResults div::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;
}





.switch-container {
  display: inline-block;
  position: relative;
}

.switch {
  display: none;
}

.switch-label {
  display: block;
  width: 50px;
  height: 25px;
  background-color: #FF5252 !important;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch-label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.switch:checked + .switch-label {
  background-color: #0D47A1 !important;
}

.switch:checked + .switch-label:before {
  transform: translateX(25px);
}

</style>