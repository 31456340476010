<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="pa-4 text-center">
    <v-dialog v-model="propsCliente.dialog" persistent>
      <v-card>
        <v-toolbar class="text-h6 ps-4" color="primary" dark> Cliente </v-toolbar>
        <v-card-text>
        <v-form  ref="formularioCliente">
          <div class="d-flex">
            <v-text-field 
               label="NIT" 
               v-model="cliente.nit" 
               class="  me-6"
               color="primary"
               density="compact" 
               variant="outlined">
             </v-text-field>
            <v-text-field 
               label="Razón social" 
               v-model="cliente.razonSocial" 
               class="  me-6"
               color="primary"
               density="compact" 
               variant="outlined">
             </v-text-field>
          </div>
          <div class="d-flex">
            
            <v-autocomplete
              :return-object="false"
              :items="ciudades"
              label="Ciudad" 
              autocomplete="off"
              item-text="ciudad"
              item-value="idCiudad"
              v-model="cliente.ciudad.idCiudad" 
              variant="outlined" 
              density="compact"
              @keyup="filtroCiudad($event.target.value)"
              hide-details="true"
              class="  me-6"
            >
            </v-autocomplete>

            <v-text-field 
               label="Dirección" 
               v-model="cliente.direccion" 
               class="  me-6"
               color="primary"
               density="compact" 
               variant="outlined">
             </v-text-field>
            <v-text-field 
               label="Teléfono" 
               v-model="cliente.telefono" 
               class="  me-6"
               color="primary"
               density="compact" 
               variant="outlined">
             </v-text-field>
            <v-text-field 
               label="Celular" 
               v-model="cliente.celular" 
               class="  me-6"
               color="primary"
               density="compact" 
               variant="outlined">
             </v-text-field>
          </div>
          
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn size="small" color="error" variant="plain" @click="cerrarDialog()">
                Cerrar
              </v-btn>
              <v-btn size="small" color="success" @click="saveCliente()"> Guardar </v-btn>
            </div>

          </v-form>
        </v-card-text>

        <v-divider></v-divider>

      </v-card>
    </v-dialog>
    
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations } from "vuex";

export default {
  components: {},
  created() { },
  data() {
    return {
      filtros: {
        ciudad: ''
      },
      ciudades: [],
      cliente: {
        nit: null,
        razonSocial: null,
        ciudad: {
          idCiudad: null,
        },
        direccion: null,
        telefono: null,
        celular: null,
      },
      productosCliente: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        numberOfElements: 0,
        number: 0,
        size: 10
      },
      timer: null,
    }
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    inputHandler(func) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if(typeof func == 'function'){
          func();
        }
      }, 500);
    },
    filtroCiudad(search){
      this.filtros.ciudad = search;
      this.inputHandler(this.getCiudades);
    },
    saveCliente() {
      const {
        ciudad,
        ... cliente
      } = this.cliente;
      cliente.idCiudad = ciudad.idCiudad
      if (this.cliente.idCliente) {
        this.actualizarCliente(cliente);
      } else {
        this.crearCliente(cliente);
      }
    },
    actualizarCliente(cliente) {
      this.$http.put('/msa-tools/api/v1/homologador-cliente', cliente)
        .then(() => {
          this.propsCliente.dialog = false;
        })
        .catch(error => {
          console.error(error);
          this.showError(error.response.data.mensaje.join(','))
        })
    },
    crearCliente(cliente) {
      this.$http.post('/msa-tools/api/v1/homologador-cliente', cliente)
        .then(() => {
          this.showSuccess("Cliente creado con exito")
          this.cerrarDialog();
        })
        .catch(error => {
          console.error(error);
          this.showError(error.response.data.mensaje.join(','))
        })
    },
    getClienteById() {
      this.$http.get(`/msa-tools/api/v1/homologador-cliente/${this.propsCliente.cliente.idCliente}`)
        .then((res) => {
          const cliente = res.data;
          this.ciudades.push(cliente.ciudad);
          this.cliente = {
            idCliente: cliente.idCliente,
            nit: cliente.nit,
            razonSocial: cliente.razonSocial,
            ciudad: {
              idCiudad: cliente?.ciudad?.idCiudad || null,
            },
            direccion: cliente.direccion,
            telefono: cliente.telefono,
            celular: cliente.celular,
          }
        }).catch(error => {
          console.error(error);
          this.showError(error.response.data.mensaje.join(','))
        })
    },
    getCiudades(){
      this.$http.get(`/msa-tools/api/v1/ciudad`, {
        params: {
          size: 5,
          page: 0,
          ciudad: this.filtros.ciudad || '',
          sort: "ciudad,asc"
        }
      })
      .then(res => {
        this.ciudades = res.data.content;
      })
      .catch(error => {
        console.error(error);
      })
    },
    cerrarDialog(){
      this.$refs.formularioCliente?.reset();
      this.propsCliente.dialog = false;
      this.cliente = {
        nit: null,
        razonSocial: null,
        ciudad: {
          idCiudad: null,
        },
        direccion: null,
        telefono: null,
        celular: null,
      };
    }
  },
  watch: {
    'propsCliente.dialog': function(newValue) {
      if(newValue && this.propsCliente?.cliente?.idCliente){
        this.getClienteById();
      }
    },
  },
  computed: {},
  mounted() {
    this.getCiudades();
  },
  props: {
    propsCliente: Object,
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped></style>