<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Agosta 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section>

    <v-text-field 
      class="ps-3 mt-2" 
      label="NIT" 
      v-model="filtros.nit" 
      v-on:keyup="inputHandler()" 
      density="compact"
      color="primary"
      variant="outlined" hide-details>
    </v-text-field>

    <v-data-table
      :headers="headers" 
      :items="items.content"
      :server-items-length="items.totalElements"
      items-per-page-text="Items por página"
      :items-per-page-options="itemsPerPageOptions" 
      @update:items-per-page="(i) => cambiarItemsPagina(i)"
      @update:page="(p) => cambiarPagina(p)"
      class="tableResults ma-3" >
      <template v-slot:header.config>
        <v-btn small class="ms-2" fab color="success" @click="modificar(null)">
          <v-icon dark> add </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.config="{ item }">
        <v-btn 
          small 
          class="ms-2" 
          fab 
          color="warning" 
          @click="modificar(item)"
        >
          <v-icon dark> edit </v-icon>
        </v-btn>
        <v-btn 
            small
            class="ms-2" 
            fab
            color="primary" 
            @click="productos(item)"
          >
          <v-icon dark> inventory_2 </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <HomologadorClienteSave :propsCliente="propsCliente"></HomologadorClienteSave>
    <HomologadorClienteProducto :propsClienteProducto="propsClienteProducto"></HomologadorClienteProducto>
  </section>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import HomologadorClienteSave from "@/components/homologador/HomologadorClienteSave.vue";
import HomologadorClienteProducto from "@/components/homologador/HomologadorClienteProducto.vue";

export default {
  name: "HomologadorCliente",
  components: {
    HomologadorClienteSave,
    HomologadorClienteProducto
  },
  data: () => ({
    roles: [],
    timer: null,
    filtros: {
      nit: null
    },
    headers: [
      { text: "NIT", value: "nit", sortable: false },
      { text: "RAZÓN SOCIAL", value: "razonSocial", sortable: false },
      { text: "CIUDAD", value: "ciudad.ciudad", sortable: false },
      { text: "DIRECCIÓN", value: "direccion", sortable: false },
      { text: "TELEFONO", value: "telefono", sortable: false },
      { text: "CELULAR", value: "celular", sortable: false },
      { text: "", value: "config", sortable: false },
    ],
    itemsPerPageOptions: [10, 20, 30, 40],
    items: {
      content: [],
      totalElements: 0,
      totalPages: 0,
      numberOfElements: 0,
      number: 0,
      size: 10
    },
    propsCliente: {
      cliente: {},
      dialog: false
    },
    propsClienteProducto: {
      idCliente: null,
      dialog: false
    }
  }),
  computed: {
    ...mapState(["auth", "notify", "busy", "trazabilidad"]),
  },
  watch: {
    'propsCliente.dialog': function (newValue) {
      if (newValue == false) {
        this.getClientes();
      }
    }
  },
  mounted() {
    this.getClientes();
    this.roles = this.auth.roles;
  },
  methods: {
    inputHandler() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getClientes();
      }, 500);
    },
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    getClientes() {
      this.items.content = [];
      this.$http.get('msa-tools/api/v1/homologador-cliente', {
        params: {
          size: this.items.size,
          page: this.items.number,
          nit: this.filtros.nit || '',
          sort: "idCliente,desc"
        }
      }
      ).then(({ data }) => {
        this.items = data;
      })
      .catch(error => {
        console.error(error);
        this.showError(error.response.data.mensaje.join(','))
      })
    },
    cambiarItemsPagina(size) { this.items.size = size; this.getClientes(); },
    cambiarPagina(page) { this.items.number = page - 1; this.getClientes(); },
    modificar(item) {
      if(item?.idCliente) this.propsCliente.cliente.idCliente = item.idCliente;
      else this.propsCliente.cliente.idCliente = null;
      this.propsCliente.dialog = true;
    },
    productos(item){
      this.propsClienteProducto.dialog = true;
      this.propsClienteProducto.idCliente = item.idCliente;
    },
    eliminar(item) {
      this.$http.delete(`/msa-tools/api/v1/homologador-cliente/${item.idCliente}`)
        .then(() => {
          this.getClientes();
        }).catch(error => {
          console.error(error);
          this.showError(error.response.data.mensaje.join(','))
        })
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>

::v-deep .tableResults div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tableResults div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tableResults div {
  --sb-track-color: #d1cfcf;
  --sb-thumb-color: #8b8585;
  --sb-size: 8px;
}

::v-deep .tableResults div::-webkit-scrollbar {
  width: var(--sb-size)
}

::v-deep .tableResults div::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

::v-deep .tableResults div::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;
}
</style>